<template>
  <div class="alert-actions">
    <div v-for="(alertAction, i) in value" :key="i" class="input-row">
      <span v-if="i === 0" class="then-and">then</span>
      <span v-else class="then-and">and</span>
      <div class="actions-inputs">
        <x-select
            v-model="alertAction.connector"
            :items="connectors"
            :rules="rules.required"
            class="action"
            item-text="name"
            item-value="id"
            label="Action"/>
        <duration-text-field v-model="alertAction.retry" :rules="rules.duration" class="duration" label="Remind after"/>
      </div>
      <div class="list-actions">
        <v-btn v-if="value.length > 1" class="delete-button" color="red" icon @click="deleteAction(i)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <div v-else class="delete-icon-placeholder"/>
        <v-btn v-if="i === value.length - 1" class="primary" @click="addAction">Add action</v-btn>
        <div v-else class="add-action-placeholder"/>
      </div>
    </div>
  </div>
</template>

<script>
import XSelect from '@/components/basic/XSelect';
import DurationTextField from '@/components/extended/DurationTextField';

export default {
  name: 'AlertActions',
  components: {
    DurationTextField,
    XSelect,
  },
  props: {
    value: Array,
    meta: Object,
    rules: Object,
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  computed: {
    connectors() {
      let connectors = [];
      if (this.meta.connectors) {
        Object.entries(this.meta.connectors).forEach((connector) => {
          connectors.push({
            id: parseInt(connector[0]),
            name: connector[1],
          });
        });
      }
      return connectors;
    },
  },
  methods: {
    addAction() {
      const value = [...this.value];
      value.push({
        connector: '',
        retry: '',
      });
      this.$emit('input', value);
    },
    deleteAction(i) {
      const value = [...this.value];
      value.splice(i, 1);
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
.alert-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.input-row {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.then-and {
  margin-top: 8px;
  flex: 0 0 32px;
  text-align: center;
}

.delete-icon-placeholder {
  width: 36px;
  height: 36px;
}

.delete-button {
  margin-top: 2px;
}

.add-action-placeholder {
  height: 32px;
  min-width: 127px;
}

.actions-inputs {
  display: flex;
  gap: 20px;
  flex: 1 1 auto;
}

.action {
  width: calc(70% - 10px);
}

.duration {
  width: calc(30% - 10px);
}

.list-actions {
  display: flex;
  gap: 20px;
  flex: 0 0 184px;
  align-items: center;
}
</style>