<template>
  <div class="input-row">
    <x-select
        v-model="internalValue.field"
        :items="fields"
        :rules="rules.required"
        :tooltip="typeTooltip"
        class="type"
        label="Type"/>
    <x-select v-model="internalValue.op" :items="operators" :rules="rules.required" class="operator" label="Operator"/>
    <x-text-field
        v-model="internalValue.valueAndUnit"
        :rules="rules[ruleKey]"
        :tooltip="valueTooltip"
        class="value"
        label="Value"/>
    <duration-text-field v-model="internalValue.timerange" :rules="rules.duration" class="timerange" label="Monitoring period"/>
  </div>
</template>

<script>
import XTextField from '@/components/basic/XTextField';
import XSelect from '@/components/basic/XSelect';
import DurationTextField from '@/components/extended/DurationTextField';

export default {
  name: 'AlertCondition',
  components: {
    DurationTextField,
    XTextField,
    XSelect,
  },
  props: {
    init: Object,
    value: Object,
    rules: Object,
    meta: Object,
    measurement: String,
    ruleKey: String,
    possibleUnits: Array,
  },
  data() {
    return {
      operators: [
        'greater', 'less', 'equal', 'equal or greater', 'equal or less', 'not equal',
      ],
      internalValue: {...this.value},
    };
  },
  watch: {
    internalValue: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  computed: {
    fields() {
      if (!this.measurement || !this.meta.measurements[this.measurement]) return [];
      return Object.keys(this.meta.measurements[this.measurement].fields);
    },
    typeTooltip() {
      if (!this.measurement) return 'You must select a measurement first.';
      return '';
    },
    valueTooltip() {
      if (!this.possibleUnits) return '';
      if (!this.value.field) return 'You must select a type first.';
      const numbers = [4, 7, 69, 88];
      let valueTooltip = 'Examples: ';
      for (let i = 0; i < this.possibleUnits.length; i++) {
        const possibleUnit = this.possibleUnits[i];
        valueTooltip += numbers[i % numbers.length] + possibleUnit;
        if (i < this.possibleUnits.length - 1) valueTooltip += ', ';
      }
      if (!this.possibleUnits.length) {
        valueTooltip += numbers.join(', ');
      }
      valueTooltip += '.';
      if (this.possibleUnits.indexOf('µs') >= 0) {
        valueTooltip += `<br />'µs' can also be written as 'us'.`;
      }
      return valueTooltip;
    },
  },
};
</script>

<style scoped>
.type {
  width: calc(25% - 15px);
}

.operator, .value, .timerange {
  width: calc(25% - 15px);
}
</style>