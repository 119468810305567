<template>
  <div>
    <x-dialog
        :buttons="buttons"
        :persistent="modified"
        :title="computedTitle"
        :value="value"
        :width="width"
        @input="$emit('input', $event)"
        @click:outside="closeDialog">
      <template #dialog-content>
        <v-form ref="form" v-model="valid" :disabled="loading">
          <slot name="dialog-content"/>
        </v-form>
      </template>
    </x-dialog>
    <yes-no-dialog
        v-model="unsavedChangesDialog"
        text="You have unsaved changes."
        title="Really close?"
        width="240"
        yes-icon="mdi-close"
        @yes="$emit('input', false)"/>
    <loading-dialog v-model="this.saving"/>
  </div>
</template>

<script>
import XDialog from '@/components/basic/XDialog';
import YesNoDialog from '@/components/extended/YesNoDialog';
import LoadingDialog from '@/components/basic/LoadingDialog';

export default {
  name: 'FrameworkFormDialog',
  components: {
    LoadingDialog,
    YesNoDialog,
    XDialog,
  },
  props: {
    value: Boolean,
    title: String,
    item: Object,
    itemId: Number,
    itemName: String,
    width: {
      Number,
      String,
    },
    customValid: Boolean,
  },
  data() {
    return {
      buttons: [
        {
          text: 'Save',
          icon: 'mdi-content-save',
          color: 'save',
          textColor: 'white',
          disabled: true,
          click: this.save,
        },
        {
          text: 'Cancel',
          icon: 'mdi-cancel',
          color: 'secondary',
          textColor: 'white',
          click: this.closeDialog,
        },
      ],
      loading: false,
      saving: false,
      valid: false,
      error: false,
      unsavedChangesDialog: false,
      modified: false,
      waitingForInput: false,
    };
  },
  watch: {
    value(value) {
      if (value) {
        this.$emit('open');
        this.reload();
      } else {
        this.$emit('close');
      }
    },
    item: {
      deep: true,
      handler() {
        this.modified = true;
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
    },
    valid() {
      this.buttons[0].disabled = !this.combinedValid;
    },
    customValid() {
      this.buttons[0].disabled = !this.combinedValid;
    },
  },
  created() {
    this.modified = false;
  },
  computed: {
    saveDisabled() {
      return this.loading || !this.valid || !!this.error;
    },
    computedTitle() {
      if (this.title) return this.title;
      if (this.itemName) {
        if (!this.itemId) return `New ${this.itemName}`;
        if ('name' in this.item) return `Edit ${this.itemName}: ${this.item.name}`;
        return `Edit ${this.itemName}`;
      }
      return 'Form Dialog';
    },
    combinedValid() {
      return this.valid && (this.customValid || this.customValid === undefined);
    },
  },
  methods: {
    reload() {
      if (this.itemId === 0) {
        this.error = '';
        this.$emit('reload', null);
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
        this.waitForInput();
        return;
      }
      this.loading = true;
      this.frameworkGetRequest(undefined, 'getItem', {id: this.itemId}, (item) => {
        this.loading = false;
        this.$emit('reload', item);
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.waitForInput();
          });
        });
      });
    },
    save() {
      if (this.valid && this.customValid) {
        this.saving = true;
        this.$emit('before-save');
        const item = this.item;
        if (this.itemId === 0) {
          this.frameworkPutRequest(undefined, 'createItem', {item: this.item}, (response) => {
            item.id = response.id;
            this.$emit('created', item);
            this.$emit('input', false);
            this.loading = false;
            this.saving = false;
          });
        } else if (this.itemId > 0) {
          this.frameworkPostRequest(undefined, 'updateItem', {
            id: this.itemId,
            item: this.item,
          }, () => {
            this.$emit('updated', item);
            this.$emit('input', false);
            this.loading = false;
            this.saving = false;
          });
        }
      }
    },
    closeDialog() {
      if (!this.modified) {
        this.$emit('input', false);
      } else {
        this.unsavedChangesDialog = true;
      }
    },
    waitForInput() {
      this.$nextTick(() => {
        this.waitingForInput = true;
        this.modified = false;
      });
    },
  },
};
</script>

<style scoped>

</style>